// import authenticate from "@/auth/authenticate";

export default [
  {
    path: "/redeem",
    component: () => import("@/views/global/Clear"),
    meta: { layout: "auth" },
    // beforeEnter: authenticate,

    children: [
      {
        path: "courtesy/:partySlug/:promotionalId",
        name: "redeem.courtesy",
        component: () => import("@/views/redeem/CourtesyPromotionalRedeem"),
      },

      {
        path: "*",
        redirect: "/",
      },
    ],
  },

];
