import authenticate from "@/auth/authenticate";
import hasSelectedOrganization from "@/auth/hasSelectedOrganization";

export default [
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: "admin.index" */ "@/views/global/Index"),
    beforeEnter: authenticate,
    children: [
      {
        path: "",
        name: "admin.index",
        component: () =>
          import(/* webpackChunkName: "admin.index" */ "@/views/admin/Index"),
      },
      {
        path: "organization/create",
        name: "admin.create",
        component: () =>
          import(
            /* webpackChunkName: "admin.index" */ "@/views/admin/organization/Create"
          ),
      },
      {
        path: "organization",
        name: "admin.dashboard",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.index" */ "@/views/admin/organization/Home"
          ),
      },
      {
        path: "organization/profile",
        name: "admin.profile",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.index" */ "@/views/admin/organization/Profile"
          ),
        meta: { permission: 256 },
      },
      {
        path: "organization/members",
        name: "admin.members",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.index" */ "@/views/admin/organization/Members"
          ),
        meta: { permission: 8 },
      },
      {
        path: "organization/parties",
        name: "admin.parties",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.party" */ "@/views/admin/party/OrganizationParties"
          ),
        meta: { permission: [2, 4, 16, 64, 128, 512, 8192, 524288, 131072] },
      },
      {
        path: "organization/products",
        name: "admin.productsManagement",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.party" */ "@/views/admin/products/ProductsManagement"
          ),
        meta: { permission: [16384, 1048576, 2097152] },
      },
      {
        path: "organization/parties/create",
        name: "admin.party.create",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.party" */ "@/views/admin/party/PartyCreate"
          ),
        meta: { permission: 2 },
      },
      {
        path: "organization/parties/:partyId/",
        name: "admin.party.details",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.party" */ "@/views/admin/party/PartyDetails"
          ),
        meta: { permission: [2, 4] },
      },
      {
        path: "organization/parties/:partyId/management",
        name: "admin.party.management",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.party" */ "@/views/admin/party/PartyManagement"
          ),
        meta: { permission: [16, 64, 128, 512] },
      },
      {
        path: "organization/transactions",
        name: "admin.transactions",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.party" */ "@/views/admin/financial/Index"
          ),
        meta: { permission: [2048] },
      },
      {
        path: "organization/transactions/:partyId",
        name: "admin.transactions.party",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.party" */ "@/views/admin/financial/Index"
          ),
        meta: { permission: [2048] },
      },
      {
        path: "organization/promotions",
        name: "admin.promotions",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.extra" */ "@/views/admin/promotions/Index.vue"
          ),
        meta: { permission: 65536 },
      },
      {
        path: "organization/promotions/create",
        name: "admin.promotions.create",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.extra" */ "@/views/admin/promotions/Create.vue"
          ),
        meta: { permission: 65536 },
      },
      {
        path: "organization/promotions/:id",
        name: "admin.promotions.details",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.extra" */ "@/views/admin/promotions/Details.vue"
          ),
        meta: { permission: 65536 },
      },
      {
        path: "organization/entrance",
        name: "admin.entrance",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.entrance" */ "@/views/admin/entrance/PartySelect.vue"
          ),
        meta: { permission: [131072, 262144] },
      },
      {
        path: "organization/entrance/:partyId",
        name: "admin.entrance.scan",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.entrance" */ "@/views/admin/entrance/Scanner.vue"
          ),
        meta: {
          permission: [262144],
          layout: "blank",
          backgroundColor: "#242939",
        },
      },
      {
        path: "organization/entrance/:partyId/management",
        name: "admin.entrance.management",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.entrance" */ "@/views/admin/entrance/EntranceManagement.vue"
          ),
        meta: { permission: [131072] },
      },
      {
        path: "organization/entrance/:partyId/sessions",
        name: "admin.entrance.sessions",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.entrance" */ "@/views/admin/entrance/EntranceSessions.vue"
          ),
        meta: { permission: [131072] },
      },

      // Membership
      {
        path: "organization/membership",
        name: "admin.membership",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.membership" */ "@/views/admin/membership/Index.vue"
          ),
        meta: { permission: [131072] },
      },
      {
        path: "organization/pos",
        name: "admin.pos",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.pos" */ "@/views/admin/pos/Index.vue"
          ),
        meta: { permission: [268435456, 536870912, 1073741824] },
      },

      {
        path: "organization/my-sales",
        name: "admin.my-sales",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.index" */ "@/views/admin/MySales.vue"
          ),
        meta: { permission: [32, 1024] },
      },
      {
        path: "organization/sell",
        name: "admin.sell",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.index" */ "@/views/admin/party/OrganizationSell"
          ),
        meta: { permission: [32, 1024] },
      },
      // {
      //   path: "organization/extra/ig",
      //   name: "admin.extra.ig",
      //   beforeEnter: hasSelectedOrganization,
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "admin.extra" */ "@/views/admin/extras/InstagramPostCheck"
      //     ),
      //   meta: { permission: [32, 1024] },
      // },
      {
        path: "organization/extra/catalog",
        name: "admin.extra.catalog",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.extra" */ "@/views/admin/extras/Catalog"
          ),
        meta: { permission: [256, 16, 64, 512, 2048, 4194304, 8388608] },
      },
      {
        path: "organization/extra/cost-simulator",
        name: "admin.extra.cost-simulator",
        beforeEnter: hasSelectedOrganization,
        component: () =>
          import(
            /* webpackChunkName: "admin.extra" */ "@/views/admin/extras/CostSimulator"
          ),
        meta: { permission: [16] },
      },
      {
        path: "*",
        name: "admin.error",
        component: () =>
          import(
            /* webpackChunkName: "admin.index" */ "@/views/global/Error.vue"
          ),
      },
    ],
  },
];
