import Vue from "vue";

const VueOfflineMixin = {
  data() {
    return {
      isOnline: false,
      isOffline: false,
      inPwa: false,
      canInstallPwa: false,
      pwaDeferredPrompt: null,
    }
  },
  methods: {
    initPwa() {
      const matchMedia = typeof window.matchMedia !== 'undefined' ? window.matchMedia : null
      const isTwa = window.sessionStorage.getItem('twa') === 'true'
      const isPwa = window.sessionStorage.getItem('pwa') === 'true'
      this.inPwa = isTwa || isPwa || (matchMedia && matchMedia('(display-mode: standalone)').matches)
      if (this.inPwa) return

      if (matchMedia) {
        matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
          this.inPwa = evt.matches
        });
      }

      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.pwaDeferredPrompt = e
        this.canInstallPwa = true
      })
    },
    initOnlineOffline() {
      navigator.onLine ? this.isOnline = true : this.isOffline = true

      const onlineHandler = () => {
        this.$emit('online')
        this.isOnline = true
        this.isOffline = false
      }

      const offlineHandler = () => {
        this.$emit('offline')
        this.isOffline = true
        this.isOnline = false
      }

      window.addEventListener('online', onlineHandler)
      window.addEventListener('offline', offlineHandler)

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('online', onlineHandler)
        window.removeEventListener('offline', offlineHandler)
      })
    },
  },
  created() {
    if (typeof window !== 'undefined') {
      this.initOnlineOffline()
      this.initPwa()
    }
  }
}

Vue.mixin(VueOfflineMixin)