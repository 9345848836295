import http from "@/http-common";

const getAll = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/co-organizer`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const set = async (orgId, partyId, data) => {
  return await http
    .put(`/admin/organization/${orgId}/party/${partyId}/co-organizer`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const remove = async (orgId, partyId, id) => {
  return await http
    .delete(`/admin/organization/${orgId}/party/${partyId}/co-organizer/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};


export default {
  getAll,
  set,
  remove
};
