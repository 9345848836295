import http from "@/http-common";

const save = async (sessionId, records, partNumber, close = false) => {
  return await http
    .post(`/support/record/${sessionId}`, {
      records,
      partNumber,
      closeSession: close,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getSession = async (sessionId) => {
  return await http
    .get(`/support/record/${sessionId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getInstanceById = async () => {
  return await http
    .get(`/support/instance`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  save,
  getSession,
  getInstanceById,
};
