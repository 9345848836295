<template>
  <div>
    <div style="position: fixed; bottom: 15px; left: 15px; z-index: 10000">
      <v-fade-transition>
        <v-card
          style="border-radius: 20px"
          rounded="xl"
          v-if="state === 'recording' || state === 'saving'"
        >
          <v-btn
            color="black"
            rounded
            large
            @click="stop"
            :class="{ pulse: state === 'recording' }"
            :disabled="state === 'saving'"
          >
            <template v-if="state === 'recording'">
              <v-icon size="24">mdi-record</v-icon>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  padding-left: 10px;
                "
              >
                <span class="d-block text-left"> Gravando... </span>
                <small> Clique para parar </small>
              </div>
            </template>

            <template v-else>
              <v-progress-circular indeterminate size="24" />
              <span style="padding-left: 10px"> Salvando... </span>
            </template>
          </v-btn>
        </v-card>
      </v-fade-transition>

      <v-fade-transition>
        <v-alert type="error" v-if="error" border="bottom">
          {{ error }}
        </v-alert>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import RECORDER from "@/services/support/recorder";

export default {
  data: () => ({
    sessionId: null,
    active: false,
    interval: null,
    script: null,
    rr: null,
    error: null,
    recorderStop: null,
    state: "idle",
    record: [],
    plugins: [],
    consoleRecord: [],
    partNumber: 1,
  }),
  methods: {
    stream(event) {
      this.record.push(event);
    },
    async load() {
      if (this.state !== "idle") return this.rrweb;
      const moduleUrl =
        "https://cdn.jsdelivr.net/npm/@rrweb/record@2.0.0-alpha.17/+esm";
      const consolePluginUrl =
        "https://cdn.jsdelivr.net/npm/@rrweb/rrweb-plugin-console-record@2.0.0-alpha.17/+esm";
      const dynamicImport = new Function("url", "return import(url)");
      const rrweb = await dynamicImport(moduleUrl);
      const consolePlugin = await dynamicImport(consolePluginUrl);
      this.rrweb = rrweb;
      this.plugins = [consolePlugin.getRecordConsolePlugin()];
      this.state = "loaded";
      return rrweb;
    },
    async start(sessionId) {
      try {
        this.sessionId = sessionId;
        await this.load();
        this.error = null;
        const recorder = this.rrweb.record({
          emit: this.stream,
          plugins: this.plugins,
        });
        this.recorderStop = recorder;
        this.state = "recording";

        await this.save();

        this.interval = setInterval(() => {
          this.save();
        }, 10000);
      } catch (error) {
        console.log(error);
        this.error = error;
        this.$root.$emit("recorder-error", error);
      }
    },

    async save(close = false, limit = 50000) {
      const records = this.record;
      try {
        this.record = [];
        const jsonl = this.jsonToJsonl(records);
        const jsonlLength = Buffer.byteLength(jsonl, "utf8");
        if (jsonlLength > limit) {
          const chunkSize = limit;
          const totalChunks = Math.ceil(jsonlLength / chunkSize);
          const chunks = [];
          for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end = start + chunkSize;
            chunks.push(jsonl.slice(start, end));
          }
          for (const chunk of chunks) {
            const response = await RECORDER.save(
              this.sessionId,
              chunk,
              this.partNumber,
              // TODO: validate if close, then just the last one close true
              close
            );
            console.log(response);
            this.partNumber = response.data?.partNumber;
          }
        } else {
          const response = await RECORDER.save(
            this.sessionId,
            jsonl,
            this.partNumber,
            close
          );
          this.partNumber = response.data?.partNumber;
        }
      } catch (error) {
        console.log(error);
        this.record.unshift(...records);
      }
    },
    async stop() {
      try {
        this.state = "saving";
        if (this.recorderStop) {
          this.recorderStop();
          this.recorderStop = null;
        }
        this.interval = null;
        clearInterval(this.interval);
        await this.save(true);
      } catch (error) {
        console.log(error);
        this.error = error;
      } finally {
        this.state = "loaded";
      }
    },
    jsonToJsonl(data) {
      let jsonArray;
      if (Array.isArray(data)) jsonArray = data;
      else if (typeof data === "object") jsonArray = [data];
      else throw new Error("Input inválido: input deve ser um objeto ou array");
      return jsonArray.map((item) => JSON.stringify(item)).join("\n");
    },
  },
  mounted() {
    this.$root.$on("recorder-start", this.start);
  },
};
</script>

<style lang="scss" scoped>
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 1px rgb(255, 0, 0);
  }
  45% {
    box-shadow: 0 0 0 3px rgb(255, 63, 63);
  }
  55% {
    box-shadow: 0 0 0 3px rgb(255, 63, 63);
  }
  100% {
    box-shadow: 0 0 0 1px rgb(255, 0, 0);
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}
</style>
