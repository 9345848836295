import authenticate from "@/auth/authenticate";

export default [
  {
    path: "/support",
    component: () =>
      import(/* webpackChunkName: "app" */ "@/views/global/Index"),

    children: [
      {
        path: "",
        name: "support.index",
        redirect: "/app/ticket",
      },
      {
        path: "record",
        name: "support.record",
        meta: { layout: "Blank" },
        beforeEnter: authenticate,

        component: () =>
          import(
            /* webpackChunkName: "support" */ "@/views/support/Record.vue"
          ),
      },
      {
        path: "*",
        redirect: "/app",
      },
    ],
  },
];
