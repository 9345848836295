<template>
  <v-avatar :size="size" :color="color">
    <v-img :src="avatar" />
  </v-avatar>
</template>

<script>
export default {
  props: {
    seed: {
      type: String,
      required: true,
    },
    size: {
      type: Number | String,
      default: 30,
    },
    avatarStyle: {
      type: String,
      default: "adventurer-neutral",
    },
    color: {
      type: String,
    },
    src: {
      type: String,
    },
  },
  computed: {
    avatar() {
      return this.src || `https://api.dicebear.com/7.x/${this.avatarStyle}/svg?seed=${this.seed}.`;
    },
  },
};
</script>

<style></style>
