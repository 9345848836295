import http from "@/http-common";

const getAll = async (orgId, partyId, params) => {
  return await http
    .get(`/admin/organization/${orgId}/party/${partyId}/seller`, { params })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const update = async (orgId, partyId, sellerId, data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/seller/${sellerId}`,
      data
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const profileUpdate = async (orgId, partyId, sellerId, type = "user", data) => {
  return await http
    .put(
      `/admin/organization/${orgId}/party/${partyId}/seller/${sellerId}/profile`,
      { ...data, type }
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const profileDelete = async (orgId, partyId, sellerId, type = "user") => {
  return await http
    .delete(
      `/admin/organization/${orgId}/party/${partyId}/seller/${sellerId}/profile?type=${type}`
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getAll,
  update,
  profile: {
    update: profileUpdate,
    delete: profileDelete,
  },
};
