<template>
  <div class="pointer">
    <slot
      @click="copy"
      :copied="copied"
      :copy="copy"
      :icon="icon"
      style="cursor: pointer"
    ></slot>
    <v-icon v-if="!hideIcon">{{ icon }}</v-icon>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    copied: false,
  }),
  methods: {
    copy() {
      navigator.clipboard.writeText(this.value);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
  computed: {
    icon() {
      return this.copied ? "mdi-check" : "mdi-content-copy";
    },
  },
};
</script>

<style>
</style>